/*Main Styles*/

/*Variables*/
@import './variables-styles.scss';

/*Bootstrap 4.0 imports*/
@import 'bootstrap/scss/bootstrap-reboot';
@import 'bootstrap/scss/bootstrap-grid';
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/navbar';
@import 'bootstrap/scss/nav';
@import 'bootstrap/scss/list-group';

/*Material imports*/
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@angular/material/theming';
@include mat-core();

/*Material theme*/

$palette: (
  50: #e1f1ff,
  100: #b3ddfe,
  200: #81c6fe,
  300: #4eaffd,
  400: #289dfc,
  500: #028cfc,
  600: #0284fc,
  700: #0179fb,
  800: #016ffb,
  900: #015cfa,
  A100: #ffffff,
  A200: #edf3ff,
  A400: #bacfff,
  A700: #a1bdff,
  contrast: (
    50: $color-dark-primary,
    100: $color-dark-primary,
    200: $color-dark-primary,
    300: $color-dark-primary,
    400: $color-dark-primary,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: $color-dark-primary,
    A200: $color-dark-primary,
    A400: $color-dark-primary,
    A700: $color-dark-primary,
  ),
);

$primary: mat-palette($palette, 800);
$accent: mat-palette($palette, 800);
$theme: mat-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
    ),
    density: -2,
  )
);
@include angular-material-theme($theme);

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-shape: 12px !important;
}
.success-snackbar {
  --mdc-snackbar-container-color: #{$color-light-1};
  --mat-mdc-snack-bar-button-color: #{$color-light-1};
  border-color: $color-green;
  --mdc-snackbar-supporting-text-color: #{$color-green};
}

.info-snackbar {
  --mdc-snackbar-container-color: #{$color-light-1};
  --mat-mdc-snack-bar-button-color: #{$color-light-1};
  border-color: $appic-color-secondary;
  --mdc-snackbar-supporting-text-color: #{$appic-color-secondary};
}

.error-snackbar {
  --mdc-snackbar-container-color: #{$color-light-1};
  --mat-mdc-snack-bar-button-color: #{$color-light-1};
  border-color: $color-error;
  --mdc-snackbar-supporting-text-color: #{$color-error};
}

html,
body {
  height: 100%;
}

body {
  color: $color-dark-primary;
  font-family: $font-primary;
  font-style: normal;
  font-weight: $font-weight-normal;
  background-color: $color-light-2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-normal;
}

.bg-light-primary {
  background-color: $color-bg-light-primary;
}

.bg-light-secondary {
  background-color: $color-bg-light-secondary;
}

.bg-image-container {
  background-color: #000;
  height: 300px;
  overflow: hidden;
  position: relative;

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.6;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  @media (max-width: $max-width-md) {
    display: none;
  }
}

.main {
  position: relative;

  @media (max-width: $max-width-md) {
    margin-top: 0;
  }
}

.flex-panel-container {
  display: flex;
  max-width: 1144px;
  align-items: flex-start;
  @media (min-width: $width-xl) {
    margin: auto;
  }
  @media (max-width: $max-width-xl) {
    margin: 0px 15px 0px 15px;
  }
}

.btn {
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
}

.btn-base {
  color: #fff;
  padding: 12px 22px;
  border-radius: 50px;
  font-size: 16px !important;
}

.btn-primary {
  background-color: $appic-color-primary;
  color: #fff;
  padding: 12px 22px;
  border-radius: 50px;
  font-size: 16px !important;
}

.btn-secondary {
  background-color: $appic-color-secondary;
  color: #fff;
  padding: 12px 22px;
  border-radius: 50px;
  font-size: 16px !important;
}

.btn-grey {
  background-color: $color-light-3;
  border-radius: 50px;
  padding: 12px 22px;
  font-size: 16px;
  color: $color-dark-primary;
}

.btn-facebook {
  background-color: $color-facebook-2;
  padding: 12px 22px;
  border-radius: 50px;
  vertical-align: middle;
  color: #fff;
}
.btn-email {
  background-color: $appic-color-secondary;
  padding: 12px 22px;
  border-radius: 50px;
  vertical-align: middle;
  color: #fff;
}

.btn:disabled {
  background: $color-light-6;
}

.panel-main {
  @media (max-width: $max-width-md) {
    width: 100%;
  }
}
.panel {
  margin-bottom: 60px;
  background-color: $color-bg-light-primary;
  border-radius: 12px;
  box-shadow: 0px 10px 20px 0px rgba(73, 89, 122, 0.2);

  &.min-height {
    min-height: 700px;
  }
  &.panel-main,
  &.sales-panel {
    -webkit-box-shadow: 0px 10px 20px 0px rgba(73, 89, 122, 0.2);
    -moz-box-shadow: 0px 10px 20px 0px rgba(73, 89, 122, 0.2);
    box-shadow: 0px 10px 20px 0px rgba(73, 89, 122, 0.2);
  }
  @media (min-width: $width-md) {
    &.panel-main,
    &.sales-panel {
      flex-grow: 1;
      width: 0;
      flex-shrink: 1;
    }
  }

  @media (min-width: $width-lg) {
    background-color: $color-bg-light-primary;
    border-radius: 10px;
  }

  @media (max-width: $max-width-lg) {
    margin-bottom: 150px;
  }
}

.panel.sales-panel {
  @media (min-width: $width-lg) {
    padding: 30px 45px;
  }
}

@media screen and (max-width: $width-md) {
  .dialog-primary {
    max-width: 100vw !important;
    max-height: 100vh;
  }
}

.order-panel-container {
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 12px;
  @media (min-width: $width-lg) {
    width: 350px;
  }

  @media (max-width: $max-width-lg) {
    width: 100%;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 20;
    background-color: #fff;
    -webkit-box-shadow: 0px 8px 60px 5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 8px 60px 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 8px 60px 5px rgba(0, 0, 0, 0.2);

    &.hide {
      display: none;
    }
  }
  .order-panel {
    margin-bottom: 0;
  }
  @media (max-width: $max-width-lg) {
    box-shadow: 0px -10px 20px 0px rgba(0, 0, 0, 0.2);
  }
}

@media (max-width: $width-lg) {
  .order-panel {
    top: 7px;
    position: relative;
  }
}

footer {
  a {
    color: $color-dark-primary;
    text-decoration: none;

    &:hover {
      color: $color-dark-primary;
      text-decoration: underline;
    }
  }
}

.back {
  cursor: pointer;
  position: absolute;
  top: -40px;
}

app-global-navigation {
  height: 70px;
}

.wrapper {
  min-height: calc(94% - 70px);
}

.backdrop {
  opacity: 0;
  -webkit-transition: opacity 0.5s linear;
  -moz-transition: opacity 0.5s linear;
  -ms-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;

  &.show {
    opacity: 0.5;
    visibility: visible;
    position: fixed;
    z-index: 10;
    background-color: #000;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
}

.dark-steps {
  @media (min-width: $width-md) {
    .steps {
      .step {
        background-color: #2f2f2f;
        color: #fff;
      }
      .divider {
        background-color: #2f2f2f;
      }
    }
  }
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.custom-error {
  font-size: 11px;
  color: $color-error;
  margin: 5px 0;
}

.mat-mdc-select-panel {
  padding: 0 !important;
}

mat-form-field .mat-mdc-text-field-wrapper {
  background-color: $color-light-5 !important;
  border: 1px solid $color-light-3 !important;
  border-radius: 3px !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  width: 100% !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 0s 600000s,
    color 0s 600000s !important;
}
.grecaptcha-badge {
  visibility: hidden;
  bottom: 55px !important;
  display: none;
}

mat-select.no-arrow .mat-mdc-select-arrow-wrapper {
  display: none !important;
}
