/*Fonts settings*/
$font-primary: 'Montserrat', 'Helvetica Neue', sans-serif;
$font-weight-bold: 700;
$font-weight-semi-bold: 500;
$font-weight-normal: 400;
$font-weight-light: 300;

$font-icons: 'Material Icons';

/*Colors*/
$color-bg-light-primary: #fff;
$color-bg-light-secondary: #ececec;
$color-bg-light-tertiary: #f2f2f2;
$color-dark-primary: #222222;
$color-dark-secondary: #a5a5a5;

$color-dark-1: #19080e;
$color-dark-2: #657786;
$color-dark-3: #000;
$color-dark-4: #4b4b4b;

$color-light-1: #fff;
$color-light-2: #f5f7fb;
$color-light-3: #e6ebf0;
$color-light-4: #ecf0f8;
$color-light-5: #f7f9fe;
$color-light-6: #bebebe;

$color-error: #f44336;
$color-green: #51c73e;

/*appic-colors*/
$appic-color-primary: #ff005a;
$appic-color-secondary: #028cfc;

$color-facebook-2: #0074e2;

$app-header-mobile-height: 130px;
/*RWD*/
$width-xl: 1200px;
$max-width-xl: 1199px;
$width-lg: 992px;
$max-width-lg: 991px;
$width-md: 768px;
$max-width-md: 767px;
$width-sm: 400px;
$max-width-sm: 399px;

$grid-breakpoints: (
  xs: 0,
  sm: 400px,
  md: 768px,
  lg: 992px,
  xl: 1280px,
);
